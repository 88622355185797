<div class="happy-hours" *ngIf="timer.active || timer.now" [routerLink]="timer.now ? '/main/menu' : null">
  <div class="first">
    <div class="drinks">
      <div>drinks</div>
      <div>& apps</div>
    </div>
    <div class="price">
      <div>starting at</div>
      <div><sup>$</sup>4</div>
    </div>
  </div>
  <div class="second">
    <img src="/themes/boston-pizza/assets/images/appy-hours.png" alt="">
  </div>
  <div class="third">
    <div class="start-in" *ngIf="timer.active && !timer.now">
      <div>Starts In</div>
      <div>{{ timer.activeCountdownTime }}!</div>
    </div>
    <div class="menu" *ngIf="timer.now">
      View Menu
    </div>
  </div>
</div>
