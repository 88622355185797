import { environment } from '@env/environment';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideCore } from '@core';
import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideCore({
      name: 'Oongalee ExcuseMe - Denny\'s',
      environment: environment.machineName,
      debug: environment.debug,
      authPagePath: 'auth',
      mainPagePath: 'main',
      api: {
        baseUrl: environment.apiUrl,
      },
      websocket: {
        baseUrl: environment.socketApi,
      },
      featureToggle: environment.featureToggle,
      backgroundPlayer: {
        url: environment.backgroundPlayer,
        ad: {
          url: environment.backgroundAdPlayer,
          color: environment.backgroundAdPlayerColor,
        },
      },
      sentry: {
        dsn: environment.sentry.dsn,
        environment: environment.machineName,
      },
      update: {
        checkStrategy: environment.update.checkStrategy,
        activateStrategy: environment.update.activateStrategy,
      },
      serviceWorker: {
        enabled: environment.serviceWorker,
      },
      routes: appRoutes,
    }),
    provideZoneChangeDetection({
      eventCoalescing: true,
    }),
    provideAnimationsAsync(),
  ],
};
