<div class="interacts-present" *ngIf="showInteractsPresent">
  <ng-lottie
    [options]="{path: '/assets/animations/touch.json'}"
  ></ng-lottie>
</div>
<div class="interacts-present-bottom"
     *ngIf="!hideTabBar"
>
  <span class="jump">Touch the screen to interact</span>
  <div class="interacts-present-bottom-line"></div>
</div>
<div
  *ngIf="withInvoice"
  class="tab-bar tab-bar__with-bg"
  [style.--indicator-position.px]="tabIndicator.position"
  [class.hide]="!hideTabBar"
>
  <div class="tabs" #tabs appActivitySection="TabBar">
    <button
      class="tab side-tab"
      [routerLink]="isMenuPage ? '/main/home' : '/main/menu'"
      routerLinkActive="active"
      [appUserActivityClickTracking]="isMenuPage ? 'MenuClose' : 'MenuOpen'"
    >
      <img src="/themes/dennys/assets/images/menu-1.svg" alt=""/>
    </button>

    <button
      class="tab call-menu show"
      indicatorAccent
      routerLink="/main/home"
      routerLinkActive="active"
      appUserActivityClickTracking="PageClose"
    >
      <img src="/themes/dennys/assets/images/dennys-logo-1.png" alt=""/>
    </button>

    <button
      *ngIf="withInvoice"
      class="tab side-tab pay"
      [routerLink]="isInvoicePage ? '/main/home' : '/main/invoice'"
      routerLinkActive="active"
      [appUserActivityClickTracking]="isInvoicePage ? 'InvoicePageClose' : 'InvoicePageOpen'"
    >
      <img src="/themes/dennys/assets/images/bill-1.svg" alt=""/>
    </button>
  </div>
</div>
